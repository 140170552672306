* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;
  display: inline-block;
}

img {
  max-width: 100%;
  height: auto;
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

:root {
  --custom-font-color: rgb(94, 94, 94);
  --custom-color: #70825d;
  --light-color: rgb(173, 199, 144);
  --header-background-color: #a4bf86;
  --button-color: rgb(148, 171, 123);
  --border: 4px solid rgb(112, 130, 93);
  --button-color-hover: rgb(180, 207, 149);
  --white-color: #ffff;
  --black-color: #000;
  --error-color: rgb(255, 0, 0);
  --icon-hover: rgb(180 207 149);
  --mobile-sider-color: #f5f5f5;
  --light-grey-color: #f2f2f2;
  --placeholder-color: #c9c9c9;
  --hover-btn: #2e2e38;
  --grey-color: #ccc;
}

.btn-secondary,
.btn-primary,
.custom-btn {
  background-color: var(--button-color);
  border-bottom: var(--border);
  min-height: 56px;
  -webkit-transition-duration: .3s;
  -o-transition-duration: .3s;
  transition-duration: .3s;
  font-size: 16px;
  padding: 8px 32px;
  font-weight: 700;
  color: var(--black-color);
  letter-spacing: 0.143em;
  text-transform: uppercase;
  border-top: none;
  border-left: none;
  border-right: none;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  cursor: pointer;
  line-height: normal;
  align-content: center;
}

.btn-secondary:hover,
.btn-primary:hover,
.custom-btn:hover {
  background-color: var(--light-color);
}

p {
  color: var(--custom-font-color);
}

button {
  border: none;
  background: none;
}

.section-heading h2:after {
  content: "";
  background: var(--button-color);
  height: 4px;
  width: 100px;
  display: block;
  top: 100%;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
}

.section-heading h2 {
  position: relative;
  padding-bottom: 15px;
}

:not(.btn-check)+.btn:active:focus-visible {
  box-shadow: 0 0 0 0.25rem rgb(148 171 123 / 32%);
}

.form-select,
.form-control,
input {
  width: 100%;
  padding: 8px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid var(--light-grey-color);
  background-color: var(--light-grey-color);
  outline: none;
  font-size: 16px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: unset;
}

.form-select::placeholder,
.form-control::placeholder,
input::placeholder {
  color: var(--placeholder-color);
  font-weight: 400;

}

.form-select:focus,
.form-control:focus,
input:focus {
  border-radius: unset;
  border: transparent;
  border: 1px solid var(--black-color);
  -webkit-box-shadow: none;
  box-shadow: none;
}

label,
.form-label {
  margin-bottom: 5px;
  font-weight: 500;
  position: relative;
}

.error {
  color: var(--error-color);
  font-size: 0.9em;
  position: absolute;
  left: 0;
  top: 100%;
  font-size: 12px;
}

.floating-label::after,
.input-label:after {
  content: "*";
  color: var(--error-color);
  padding: 2px;
}

.custom-btn a {
  color: var(--black-color);
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  color: var(--custom-color);
  background-color: var(--button-color);
  border-bottom: var(--border);
  border-radius: unset;
}

.btn {
  border-radius: unset;
}

.btn:hover {
  color: var(--hover-btn);
  background-color: var(--button-color);
  border-bottom: var(--border);
}

.site-header .btn:hover {
  color: var(--hover-btn);
}

::selection {
  background: var(--custom-color);
  color: var(--white-color);
}

.form-select {
  padding-right: 30px;
}

.eye-icon {
  width: 17px;
  height: 17px;
}

.header-log {
  max-width: 90px;
}

.form-check-input:checked+span {
  background: #adc790;
  color: var(--white-color);
  font-weight: 600;
  transition: all 0.2s ease;
}

.btn-close {
  position: relative;
}

.btn-close:after {
  content: "";
  background: rgb(148, 171, 123);
  color: #000;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.site-footer {
  margin-top: auto;
}

.modal-footer {
  justify-content: space-between;

}

.bar-icn {
  cursor: pointer;
  display: inline-block;
}

.modal-footer .btn {
  min-height: 45px;
  font-size: 14px;
}

.add-child-main-row .input-group {
  flex-wrap: nowrap;
}

.Dashboard-pay-view-btn {
  background: #ffff;
  border-radius: 8px;
  padding: 0 10px;
  box-shadow: 1px 1px 0px 0px #ccc;
  font-weight: 900;
  color: #0066cc;
  font-size: 14px;
}

.not-required label::after {
  display: none;
}

.edit-enabled {
  background: rgba(173, 199, 144, 0.6784313725);
  opacity: .7;

}

.edit-enabled:hover {
  background: rgba(173, 199, 144, 0.6784313725);
}

.dashboard-sider .Dashboard-pay-view-btn {
  display: flex;
  gap: 3px;
  white-space: nowrap;
  align-items: center;

}
.admin-table{
  overflow-x: auto;
}
input#birthday {
  position: relative;
}

input[type="date"]::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' shape-rendering='geometricPrecision' text-rendering='geometricPrecision' image-rendering='optimizeQuality' fill-rule='evenodd' clip-rule='evenodd' viewBox='0 0 485 512.03'%3E%3Cpath fill-rule='nonzero' d='M439.54 512.03H45.46C20.5 512.03 0 491.52 0 466.57V106.25C0 81.29 20.49 60.8 45.46 60.8h69.28V31.71C114.74 12.65 130.43 0 148.68 0c18.36 0 33.95 12.68 33.95 31.71V60.8h119.73V31.71C302.36 12.65 318.05 0 336.3 0c18.36 0 33.95 12.68 33.95 31.71V60.8h69.29c24.96 0 45.46 20.49 45.46 45.47V466.57c0 25-20.48 45.46-45.46 45.46zM338.03 232.1h65.14c4.57 0 8.29 3.79 8.29 8.3v54.19c0 4.53-3.76 8.3-8.29 8.3h-65.14c-4.52 0-8.3-3.73-8.3-8.3V240.4c0-4.56 3.75-8.3 8.3-8.3zm-127.43 0h65.14c4.57 0 8.31 3.79 8.31 8.3v54.19c0 4.53-3.78 8.3-8.31 8.3H210.6c-4.5 0-8.29-3.73-8.29-8.3V240.4c0-4.56 3.73-8.3 8.29-8.3zm-128.77 0h65.14c4.57 0 8.3 3.79 8.3 8.3v54.19c0 4.53-3.78 8.3-8.3 8.3H81.83c-4.52 0-8.29-3.73-8.29-8.3V240.4c0-4.56 3.73-8.3 8.29-8.3zm256.2 127.8h65.14c4.57 0 8.29 3.75 8.29 8.29v54.19c0 4.55-3.74 8.29-8.29 8.29h-65.14c-4.54 0-8.3-3.72-8.3-8.29v-54.19c0-4.56 3.75-8.29 8.3-8.29zm-127.43 0h65.14c4.57 0 8.31 3.75 8.31 8.29v54.19c0 4.55-3.76 8.29-8.31 8.29H210.6c-4.53 0-8.29-3.72-8.29-8.29v-54.19c0-4.56 3.73-8.29 8.29-8.29zm-128.77 0h65.14c4.57 0 8.3 3.75 8.3 8.29v54.19c0 4.55-3.75 8.29-8.3 8.29H81.83c-4.55 0-8.29-3.72-8.29-8.29v-54.19c0-4.56 3.73-8.29 8.29-8.29zM17.3 179.87V459c0 19.58 16.09 35.75 35.75 35.75h378.9c19.68 0 35.76-16.12 35.76-35.77V179.87H17.3z' /%3E%3C/svg%3E");
  background-size: 15px 15px;
  background-repeat: no-repeat;
  pointer-events: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
}

input[type="date"] {
  position: relative;
}

.update-status-model input[name="account"] {
  position: relative;
}

@media (min-width:1440px) {
  .has-error {
    margin-bottom: 20px;
  }
}

@media (max-width:1440px) {
  .has-error {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 991px) {
  h3 {
    font-size: 24px;
  }

  .form-select,
  .form-control,
  input {
    font-size: 14px;
  }

  .has-error {
    margin-bottom: 20px;
  }
}

@media screen and (max-width:767px) {
  .form-select {
    padding-right: 30px;
  }
}

@media screen and (max-width: 600px) {
  p {
    font-size: 16px;
  }

  .btn-primary,
  .custom-btn {
    font-size: 14px;
    padding: 8px 25px;
    min-height: 45px;
  }

  .contact-form .error {
    font-size: 10px;
  }

  .has-error {
    margin-bottom: 35px;
  }


}

@media screen and (max-width: 475px) {
  h3 {
    font-size: 20px;
  }

  label,
  .form-label {
    font-size: 15px;
  }

}