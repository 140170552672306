.site-footer {
  padding-top: 50px;
  background-color: var(--black-color);

  .footer-logo {
    img {
      -webkit-backdrop-filter: invert(1);
      backdrop-filter: invert(1);
      max-width: 130px;
    }

    cursor: pointer;
  }

  .footer-text {
    margin-top: 20px;

    address,
    address+p {
      color: var(--white-color);
      font-size: 16px;
    }
  }

  .footer-items {


    span {
      font-size: 16px;
      font-weight: 500;
      color: var(--white-color);
      margin: 5px 0;
      display: inline-block;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;

      &:hover {
        color: var(--header-background-color);

        &:after {
          width: 100%;
          transition: all 0.3s ease;
        }
      }

      &:after {
        content: "";
        position: absolute;
        width: 0;
        left: 0;
        height: 1.5px;
        background: var(--header-background-color);
        top: 100%;
        transition: all 0.3s ease;
      }
    }
  }

  .footer-list {
    max-width: 200px;
    margin: 0 auto;
  }

  .footer-social {
    .footer-social-icon {
      border: 2px solid var(--white-color);
      background: var(--white-color);
      width: 35px;
      height: 35px;
      border-radius: 100%;
      margin: 3px;
      display: flex;
      position: relative;
      transition: all 0.2s ease;

      &:hover {
        border-color: var(--white-color);
        background: var(--header-background-color);
        transition: all 0.2s ease;
      }
    }

    a {
      img {
        display: block;
        margin: auto;
      }
    }
  }

  .Email,
  .phone-number {
    margin-top: 10px;
    color: var(--white-color);

    span img {
      margin: 5px;
    }

    .email-icon a,
    .phone-icon a {
      display: flex;
      align-items: center;
      color: var(--white-color);
      transition: all 0.2s ease;

      &:hover {
        color: var(--header-background-color);
        transition: all 0.2s ease;
      }
    }
  }

  .main-social-media-wrapper {
    max-width: 200px;
    margin-left: auto;
  }

  .coppy-right {
    background: var(--white-color);
    margin-top: 50px;
    padding: 10px;
    outline-offset: 30px;
    outline: 1px solid;
    text-align: center;

    p {
      color: rgb(89, 89, 89);
      font-size: 16px;
    }
  }

  @media screen and (max-width: 767px) {
    .footer-text address {
      margin-bottom: 0;
    }

    padding-top: 40px;

    .main-social-media-wrapper,
    .footer-list {
      max-width: unset;
      margin-top: 20px;
    }

    .main-footer-wrapper {
      padding: 0 10px;
    }

    .coppy-right {
      padding: 10px 20px;
    }
  }

  @media screen and (max-width: 475px) {
    .coppy-right p {
      font-size: 11px;
    }
  }
}