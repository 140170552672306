.admin-layout .sidebar-wrapper {
  display: flex;
}

.admin-layout .sidebar-wrapper .admin-sider {
  max-width: 80px;
  width: 100%;
  transition: all .3s ease;
  background: #a4bf86;
  height: calc(100vh - 100px);
  padding: 5px;
}

.admin-layout .admin-container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.admin-layout .sidebar-wrapper.open .admin-sider {
  max-width: 150px;
  width: 100%;
  transition: all .3s ease;
  background: #a4bf86;

}

.admin-layout .sidebar-wrapper .admin-sider .admin-menu {
  padding: 20px 0;
}

.admin-layout .sidebar-wrapper .admin-sider .admin-menu .menu-name {
  display: none;
}

.admin-layout .sidebar-wrapper.open .admin-sider .admin-menu .menu-name {
  display: block;
  white-space: nowrap;
}

.admin-layout .sidebar-wrapper.open .admin-sider .menu-item {
  justify-content: unset;
  border-left: 2px solid transparent;
  cursor: pointer;
}

.admin-layout .sidebar-wrapper.open .admin-sider .menu-item:hover {
  border-left: 2px solid var(--custom-color);
  color: var(--black-color);
  background: var(--white-color);
}

.admin-layout .main-dashboard-wrapper {
  height: calc(100vh - 100px);
}

.admin-layout .sidebar-wrapper .admin-content {
  max-width: calc(100% - 80px);
  width: 100%;
  transition: all .3s ease;
}

.admin-layout .sidebar-wrapper.open .admin-content {
  max-width: calc(100% - 150px);
  width: 100%;
  margin-left: auto;
}

.admin-layout .sidebar-wrapper .admin-sider .menu-item {
  width: 100%;
  padding: 3px 20px;
  margin: 10px 0;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;

}

.admin-layout .sidebar-wrapper .admin-sider .menu-item svg {
  font-size: 20px;
}

.admin-layout .site-header .logo .header-log {
  margin: 0 auto
}


.admin-layout .site-header .profile-toggle {
  display: flex;
  align-items: center;
  gap: 10px;
  background: rgb(173, 199, 144);
  padding: 15px 30px;
  cursor: pointer;
  box-shadow: 0.5px 1px 3px 0px var(--custom-color);
  text-transform: uppercase;
  letter-spacing: 0.143em;
  margin-left: auto;
}



.admin-layout .site-header .profile-toggle:hover {
  background: var(--icon-hover);
  box-shadow: 1px 1px 2px 1px var(--custom-color);
}

.admin-layout .site-header .admin-log-out a {
  display: flex;
  align-items: center;
  gap: 10px;
}

.admin-layout .site-header .profile-toggle .profile-icon {
  font-size: 24px;
}

.admin-layout .admin-dashboard {

  .no-data-found {
    color: #a4a4a4;
    text-align: center;
  }
}

.admin-mobile-header-wrapper {
  display: none;
}

.update-status-model .dynamicfield-mail {
  position: relative;
}

.update-status-model .modal-body {
  max-height: 400px;
  overflow: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: var(--mobile-sider-color);
    border-radius: 2px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: var(--mobile-sider-color);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: var(--white-color);
    background-image: -webkit-linear-gradient(top,
        var(--header-background-color) 0%,
        #adc790ad 51%,
        #adc790ad 51%,
        #94ab7b 100%);
  }
}

@media screen and (max-width:991px) {
  .admin-layout .site-header .profile-toggle {
    padding: 15px 20px;
    gap: 5px;
  }

  .admin-layout .main-dashboard-wrapper,
  .admin-layout .sidebar-wrapper .admin-sider {
    height: calc(100vh - 85px);
  }

  .document-signed-wrapper {

    margin: 10px 0;
  }
}

@media screen and (max-width:767px) {
  .admin-layout .sidebar-wrapper.open .admin-content {
    max-width: 100%;
  }

  .document-signed-wrapper {

    .col-sm-3,
    .col-sm-9 {
      padding: 0;
    }
  }

  .admin-layout {
    .sidebar-wrapper {

      .admin-content {
        max-width: 100%;
      }
    }

  }

  .admin-layout .site-header .logo .header-log {
    margin: 0;
  }

  .admin-mobile-header-wrapper {
    display: block;

    .mobile-view-bar button {
      display: block;
      margin-left: auto;
      background: rgb(173, 199, 144);
      box-shadow: 0.5px 1px 3px 0px var(--custom-color);
      padding: 8px 25px;

      svg {
        font-size: 22px;
      }
    }
  }

  .admin-bashboard-header,
  .admin-sider {
    height: calc(100vh - 82px);
    display: none;
  }


}

@media screen and (max-width:575px) {
  .document-signed-wrapper {
    flex-direction: column-reverse;
    gap: 10px;

    .col-sm-9 {

      .custom-btn {
        width: 100%;
        max-width: 100%;
      }
    }
  }

}